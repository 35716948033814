import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../../helpers';

export const ItemName = styled.div`
  &&& {
    ${({ theme }) =>
      renderStyledElementStyles(
        theme,
        theme.module.vStyles.elements?.carouselItem?.elements?.name,
      )}
`;
