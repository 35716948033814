import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../../helpers';

export const ItemContent = styled.div`
  &&& {
    .row {
      ${({ theme }) =>
        renderStyledElementStyles(
          theme,
          theme.module.dStyles.elements?.carouselItem?.elements?.content
            ?.elements?.row,
        )}
    }
  }
`;
