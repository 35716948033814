import React from 'react';
import PropTypes from 'prop-types';
import Carousel from '../../../../../shared/Carousel/Carousel';
import { TestimonialItem } from '../TestimonialItem';

const carouselSettings = {
  dots: false,
  arrows: false,
  autoplay: false,
  infinite: true,
};

const customCarouselSettings = {
  STATIC: {
    dots: true,
    autoplay: false,
  },
  SMOOTH: {
    dots: false,
    autoplay: true,
    speed: 8000,
    autoplaySpeed: 0,
    cssEase: 'linear',
  },
  ONE_AT_TIME: {
    dots: true,
    autoplay: true,
    autoplaySpeed: 5000,
  },
};

export const TestimonialCarousel = ({ rotateType, testimonials }) =>
  testimonials.length > 0 && (
    <Carousel
      embedSettings={{
        ...carouselSettings,
        ...customCarouselSettings[rotateType],
      }}
    >
      {testimonials.map((item, i) => (
        <TestimonialItem
          key={i}
          item={item}
          lazyImage={rotateType !== 'SMOOTH'}
        />
      ))}
    </Carousel>
  );

TestimonialCarousel.propTypes = {
  testimonials: PropTypes.arrayOf(PropTypes.object),
};
