import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { renderHTMLString } from '../../../../../../helpers';
import { renderStyledElementStyles } from '../../../../helpers';
import { ImageTag } from '../../../sharedComponents';
import {
  ItemWrapper,
  ItemContainer,
  ItemContent,
  ItemAuthor,
  ItemName,
  ItemJobTitle,
  ItemCompany,
  ItemHeadline,
  ItemQuote,
} from '../../styles';

const ImageTagWrapper = styled.div`
  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.dStyles.elements?.carouselItem?.elements?.testimonialLogo
        ?.imageWrap,
    )}

  img {
    margin: 0;
  }
`;

export const TestimonialItem = ({
  item: { name, jobTitle, company, headline, quote, testimonialLogo } = {},
  lazyImage,
}) => (
  <ItemWrapper>
    <ItemContainer>
      <ItemContent>
        <div className="row">
          <div className="col-xs-12 col-sm-3">
            <ItemAuthor>
              <ItemName>{name && <p>{name}</p>}</ItemName>
              {jobTitle && <ItemJobTitle>{jobTitle}</ItemJobTitle>}
              {company && <ItemCompany>{company}</ItemCompany>}
              {testimonialLogo && (
                <ImageTagWrapper>
                  <ImageTag imageConfig={testimonialLogo} lazy={lazyImage} />
                </ImageTagWrapper>
              )}
            </ItemAuthor>
          </div>
          <div className="col-xs-12 col-sm-9">
            {headline && <ItemHeadline>{headline}</ItemHeadline>}
            {quote && <ItemQuote>{renderHTMLString(quote)}</ItemQuote>}
          </div>
        </div>
      </ItemContent>
    </ItemContainer>
  </ItemWrapper>
);

TestimonialItem.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    jobTitle: PropTypes.string,
    company: PropTypes.string,
    headline: PropTypes.string,
    quote: PropTypes.string,
  }).isRequired,
};
