import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../../helpers';

export const ItemQuote = styled.div`
  &&& {
    ${({ theme }) =>
      renderStyledElementStyles(
        theme,
        theme.module.vStyles?.elements?.carouselItem?.elements?.quote,
      )}
    ${({ theme }) =>
      renderStyledElementStyles(
        theme,
        theme.module.dStyles.elements?.carouselItem?.elements?.quote,
      )}
    
    a {
      color: inherit;
    }

    p {
      ${({ theme }) =>
        renderStyledElementStyles(
          theme,
          theme.module.vStyles.elements?.carouselItem?.elements?.quote?.elements
            ?.text,
        )}
      ${({ theme }) =>
        renderStyledElementStyles(
          theme,
          theme.module.dStyles.elements?.carouselItem?.elements?.quote?.elements
            ?.text,
        )}
    }
  }
`;
