import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../../helpers';

export const ItemCompany = styled.p`
  margin: 0;

  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.vStyles.elements?.carouselItem?.elements?.company,
    )}
`;
