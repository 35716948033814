import React from 'react';
import PropTypes from 'prop-types';
import { TestimonialCarousel } from '../../innerComponents/TestimonialCarousel';
import { TestimonialButton } from '../../innerComponents/TestimonialButton';
import { SectionWrapper } from '../../styles';

export const TestimonialDefaultStyle = ({
  rotateType,
  testimonials,
  button,
}) => (
  <SectionWrapper data-testid="testimonial-module-section">
    <div className="container">
      <div className="row">
        <div className="col-xs-12">
          <TestimonialCarousel
            rotateType={rotateType}
            testimonials={testimonials}
          />
        </div>
      </div>
      {button.type && (
        <div className="row" data-testid="testimonial-module-button-container">
          <div className="col-xs-12">
            <div className="text-center">
              <TestimonialButton button={button} />
            </div>
          </div>
        </div>
      )}
    </div>
  </SectionWrapper>
);

TestimonialDefaultStyle.propTypes = {
  testimonials: PropTypes.arrayOf(PropTypes.object),
  button: PropTypes.shape({
    shown: PropTypes.bool,
    type: PropTypes.string,
  }),
};
