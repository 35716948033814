import styled from 'styled-components';
import { devices } from '../../../../helpers';

export const SectionWrapper = styled.div`
  &&& {
    position: relative;

    .slick-slider {
      width: 100%;
      @media only screen and (min-width: ${devices.breakpoints.tablet}) {
        width: 80%;
        margin: 0 auto;
      }
    }

    .slick-list {
      overflow: hidden;
      @media only screen and (min-width: ${devices.breakpoints.tablet}) {
        overflow: visible;
      }
    }

    .slick-slide {
      margin: 0 10px;
      @media only screen and (min-width: ${devices.breakpoints.tablet}) {
        margin: 0 20px;
      }
    }

    .slick-track:active {
      cursor: grab;
    }
  }
`;
