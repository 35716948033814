import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../../helpers';

export const ItemHeadline = styled.h3`
  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.dStyles.elements?.carouselItem?.elements?.headline,
    )}
`;
