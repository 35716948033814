import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';

export const ModuleWrapper = styled.div`
  position: relative;
  overflow: hidden;
  background: url('${({ backgroundImage }) => backgroundImage}') no-repeat
    center;
  background-size: cover;

  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.siteBuilderModules.testimonialModule,
    )}
  ${({ theme }) => renderStyledElementStyles(theme, theme.module.vStyles)}
  ${({ theme }) => renderStyledElementStyles(theme, theme.module.dStyles)}
`;
