import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../../helpers';

export const ItemContainer = styled.div`
  position: relative;
  height: 100%;
  font-weight: 300;
  background-color: #fff;
  color: #000;

  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.vStyles.elements?.carouselItem?.elements?.container,
    )}
  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.dStyles.elements?.carouselItem?.elements?.container,
    )}
  
  &:before {
    content: '“';
    ${({ theme }) =>
      renderStyledElementStyles(
        theme,
        theme.module.vStyles.elements?.carouselItem?.elements?.container
          ?.elements?.before,
      )}
    ${({ theme }) =>
      renderStyledElementStyles(
        theme,
        theme.module.dStyles.elements?.carouselItem?.elements?.container
          ?.elements?.before,
      )}
  }

  &:after {
    content: '”';
    ${({ theme }) =>
      renderStyledElementStyles(
        theme,
        theme.module.vStyles.elements?.carouselItem?.elements?.container
          ?.elements?.after,
      )}
    ${({ theme }) =>
      renderStyledElementStyles(
        theme,
        theme.module.dStyles.elements?.carouselItem?.elements?.container
          ?.elements?.after,
      )}
  }

  &:before,
  &:after {
    display: block;
    position: absolute;
    color: #aaaaaa;
  }
`;
