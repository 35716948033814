import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../../helpers';

export const ItemAuthor = styled.div`
  height: 100%;

  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.vStyles.elements?.carouselItem?.elements?.author,
    )}
  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.dStyles.elements?.carouselItem?.elements?.author,
    )}
`;
