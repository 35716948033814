import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import { StyledCallToAction } from '../../../sharedComponents';

export const TestimonialButton = ({ button }) => {
  const themeConfig = useContext(ThemeContext);
  const vStyles = themeConfig.module.vStyles;
  const dStyles = themeConfig.module.dStyles;

  const {
    elements: {
      callToAction: { settings: { mode: callToActionMode } = {} } = {},
    } = {},
  } = vStyles;

  return (
    <StyledCallToAction
      mode={callToActionMode}
      ctaConfig={button}
      displayStyles={dStyles}
      visualStyles={vStyles}
    />
  );
};

TestimonialButton.propTypes = {
  button: PropTypes.shape({
    shown: PropTypes.bool,
    type: PropTypes.string,
  }),
};
